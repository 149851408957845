var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
      attrs: { id: "page-login" },
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _c("h3", [_vm._v("\n          Hello world !\n        ")]),
            ]),
            _c("div", [
              _c("span", [
                _vm._v("The quick brown fox jumps over the lazy dog."),
              ]),
            ]),
            _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
              _c(
                "div",
                { staticClass: "vx-row", attrs: { "vx-justify": "flex-end" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "vx-button",
                      attrs: {
                        type: "gradient",
                        color: "danger",
                        icon: "favorite",
                      },
                    },
                    [_vm._v("Test")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "vx-button",
                      attrs: {
                        type: "gradient",
                        color: "primary",
                        icon: "turned_in_not",
                      },
                    },
                    [_vm._v("Test")]
                  ),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }